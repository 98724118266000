// Dependency
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

// Internationalization
//import i18n from "../../internationalization/i18n";
import { useTranslation } from 'react-i18next';

// Components
import Layout from "../../components/layouts";
import ProductCard from "../../components/cards/product-card";
import ProductList from "../product/hooks/product-list";
// import ProductCardGrid from "../../components/cards/product-card-grid/product-card-grid";

// Styles
import * as shopStyles from './shop.module.scss'
import Filter from "../../components/filter/filter";
import UtilsService from "../../services/utils.service";

// Data
export const query = graphql`
  query ($locale: String! = "en"){
    datoCmsHeader{
      saleLabelColor{
        hex
      }
      saleLabelBackground{
        hex
      }
      saleLabelText
    }
    centraData: centra {
      products {
        name
        id
        prices {
          price {
            value
            formattedValue
          }
        }
        media {
          source {
            type
            url
          }
        }
        collection {
          name
          id
          uri
        }
      }
    }
    shopData: datoCmsShopPage (locale: {eq: $locale}){
      locale
      title
      tiles{
        isAnchor
        title
        link
      }
      bestseller{
        centraId
        slug
        featuredImage{
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        newPrice
        locale
        title
        parentProductName
        trustpilotId
        saleBadge
        bestseller
      }
      seoMetaTags {
        tags
        id
      }
    }
    categories: allDatoCmsCategoryPage(
      filter: {locale: {eq: $locale}}
    ) {
      nodes {
          title
          seoSlug
          locale
      }
    }
    products: allDatoCmsProduct(
      sort: { fields: position, order: ASC }
      filter: {locale: {eq: $locale}}
    ) {
      nodes {
        centraId
        id
        title
        productType
        productCategory {
          name
        }
        parentProductName
        slug
        featuredImage {
           fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
        }
        locale
        trustpilotId
        saleBadge
        bestseller
        newPrice
      }
    }
  }
`;

export default function Shop(props) {
  const products = props.data.products?.nodes || [];
  const headerData = props.data.datoCmsHeader;
  const categories = props.data.categories.nodes;
  // Translation Function
  const { t } = useTranslation();
  //
  const [productList, setProductList] = useState([]);
  const [filterProductList, setFilterProductList] = useState([]);
  const [filterParams, setFilterParams] = useState(props.location.search || '?');
  const [filterCategories, setFilterCategories] = useState({ name: 'Categories', categories: [] })
  const [filterBodyParts, setFilterBodyParts] = useState({ name: 'Body part', bodyParts: [] })
  const [filterPrice, setFilterPrice] = useState()
  const [filterInStock, setFilterInStock] = useState({ name: 'In stock', inStock: [] })
  const [filterOnSale, setFilterOnSale] = useState({ name: 'On sale', onSale: [] })


  const navIsOpen = props.location && props.location.state ? props.location.state.navIsOpen : false;

  useEffect(() => {
    if (filterParams) {
      const allowedParams = ['hierarchies', 'price', 'extraDataList.bodyParts', 'inStock', 'onSale'];


      const helloRetailFilters = filterParams.replace('?', '')
        .replaceAll('=', ':')
        .replaceAll('+', ' ')
        .replaceAll('%2C', ',')
        .replaceAll('%24', '$')
        .replaceAll('%C3%A4', 'ä')
        .replaceAll('%C3%A6', 'æ')
        .replaceAll('%C3%85', 'Å')
        .replaceAll('%C3%B6', 'ö')
        .replaceAll('%C3%B8', 'ø')
        .split('&');
      let helloRetailFiltersArray = []
      //loop through filters and check for multiple values

      helloRetailFilters.filter(f => {
        const filterKey = f.split(':')[0];
        return allowedParams.includes(filterKey);
      }).forEach((filter, index) => {
        if (!filter.includes('price') && filter.includes(',')) {
          const filterValues = filter.split(':');
          const filterKey = filterValues[0];
          const filterValuesArray = filterValues[1].split(',');
          const newQueryArray = filterValuesArray.map((value) => `${filterKey}:${value}`)
          helloRetailFiltersArray.push(...newQueryArray)
        }
        else {
          helloRetailFiltersArray.push(filter)
        }
      })

      getHelloRetailProducts(helloRetailFiltersArray.filter(f => f !== ''));
    }

  }, [filterParams])

  const createFilterSearchParams = (filter) => {
    let searchParams = new URLSearchParams(window.location.search);

    if (filter === null) {
      window.history.pushState({}, '', `${props.location.pathname}`);
      setFilterParams('?');
      return '';
    }

    for (let key in filter) {
      if (key === 'price') {
        searchParams.delete('price');
      }
      //If key exists, merge values
      if (searchParams.has(key)) {
        const param_values = searchParams.get(key);
        let values = param_values.split(',');
        //If value already exists, remove it
        if (values.includes(filter[key])) {
          values = values.filter(value => value !== filter[key]);
        } else {
          values.push(filter[key]);
        }
        searchParams.delete(key);

        values.length && searchParams.append(key, values.join(','));
        continue;
      }

      searchParams.set(key, filter[key]);
    }
    window.history.pushState({}, '', `${props.location.pathname}${searchParams.size > 0 ? '?' + searchParams.toString() : ''}`);
    setFilterParams(searchParams.toString() || '?');
    return searchParams.toString();
  }

  const handleResults = (results) => {
    const filterCats = results.products.filters.find(filter => filter.name === 'hierarchies');
    const prices = results.products.filters.find(filter => filter.name === 'price');
    const bodyParts = results.products.filters.find(filter => filter.name === 'extraDataList.bodyParts');
    const inStock = results.products.filters.find(filter => filter.name === 'inStock');
    const onSale = results.products.filters.find(filter => filter.name === 'isOnSale');


    if (prices) {
      setFilterPrice({ min: parseInt(prices.min), max: parseInt(prices.max), selectedMin: prices.selectedMin ? parseInt(prices.selectedMin) : undefined, selectedMax: prices.selectedMax ? parseInt(prices.selectedMax) : undefined, name: prices.settings.title, currency: results?.products?.result[0]?.currency || 'SEK' })
    } else {
      setFilterPrice({ min: 0, max: 0, name: 'Price', currency: 'SEK' })
    }
    if (filterCats) {
      const sortedCats = UtilsService.helloRetailSortCategories(filterCats.values, props.pageContext.country);
      setFilterCategories({ name: filterCats.settings.title, categories: sortedCats })
    } else {
      setFilterCategories({ name: 'Categories', categories: [] })
    }
    if (bodyParts) {
      setFilterBodyParts({ name: bodyParts.settings.title, bodyParts: bodyParts.values })
    } else {
      setFilterBodyParts({ name: 'Body part', bodyParts: [] })
    }

    if (inStock) {
      setFilterInStock({ name: inStock.settings.title, inStock: inStock.values })
    } else {
      setFilterInStock({ name: 'In stock', inStock: [] })
    }

    if (onSale) {
      setFilterOnSale({ name: onSale.settings.title, onSale: onSale.values })
    } else {
      setFilterOnSale({ name: 'On sale', onSale: [] })
    }

    setFilterProductList(results.products.result);
  }

  const getHelloRetailProducts = (productFilters) => {
    const hrq = window?.hrq || [];
    hrq.push([
      "loadPage",
      UtilsService.getHelloRetailKey('shop', props.pageContext.country),
      {
        id: 27365,
        url: `https://flowlife.com/${props.pageContext.country}/shop/`,
        firstLoad: true,
        format: "json",
        params: {
          //filters: { brand: "Flowlife" }
        },
        products: {
          start: 0,
          count: 75,
          filters: productFilters
          //sorting: ['title DESC']
        }
      },
      handleResults
    ]);
  }


  return (
    <Layout dropDownOpened={navIsOpen} seoData={props.data.shopData.seoMetaTags} country={props.pageContext.country} location={props.location}>
      <ProductList createProductList={setProductList} isCampaign={props.location.pathname === `/${props.pageContext.country}/shop/member/`} country={props.pageContext.country} />
      <div className={shopStyles.cdShopFilter}>
        <div className="cd-max-width cd-large-width">
          <div className={shopStyles.cdShopDesc}>
            <div>
              <h1>{t("search.shopAll")}</h1>
            </div>
          </div>
          <div className={shopStyles.cdShopCats}>
            {filterCategories && filterCategories.categories.map((element, index) => {
              return (
                <span key={index} style={element.selected ? { backgroundColor: '#939292', color: "white" } : {}} onClick={() => {
                  // Push title to search
                  const filterKey = element.query.split(':')[0];
                  const filterValue = element.query.split(':')[1];
                  let filterObject = {}
                  filterObject[filterKey] = filterValue;
                  createFilterSearchParams(filterObject);
                  //const searchParams = createFilterSearchParams({ category: element.title });
                }}>{element.title.slice(0, -1).split('$').pop()}</span>
              )
            })}
          </div>
          <div className={shopStyles.cdShopFilterWrapper}>
            <Filter
              country={props.pageContext.country}
              inStock={filterInStock}
              onSale={filterOnSale}
              bodyParts={filterBodyParts}
              categories={filterCategories}
              prices={filterPrice}
              lang={props.pageContext.country}
              createFilterSearchParams={createFilterSearchParams}
              setFilterParams={setFilterParams}
              productCount={filterParams ? filterProductList.length : products.length}
              filterParams={filterParams}
            />
            {/* <Popover.Root>
              <Popover.Trigger asChild>
                <button className="cd-shop-select cd-shop-select-cats" aria-label="Category">
                  Category
                  <ArrowDownIcon />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="PopoverContent cd-shop-select-content" sideOffset={5} align="start">
                  {categories && categories.map((element, index) => {
                    return (
                      <Popover.Close className="cd-filter-modal-category" key={index}>
                        <Link to={`/${props.pageContext.country}/${element.seoSlug}`} >{element.title}</Link>
                      </Popover.Close>
                    )
                  })}
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="cd-shop-select" aria-label="Sort">
                  Recommended
                  <ArrowDownIcon />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="PopoverContent cd-shop-select-content" sideOffset={5} align="end">
                  <Popover.Close className="cd-filter-modal-category">
                    Recommended
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    New
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    Highest price
                  </Popover.Close>
                  <Popover.Close className="cd-filter-modal-category">
                    Lowest price
                  </Popover.Close>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root> */}
          </div>
        </div>
      </div>
      <div className={shopStyles.cdShopWrapper}>
        <section className={shopStyles.cdShopCatSection} id={props.pageContext.category10}>
          {filterProductList.length > 0 ? <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {filterProductList && filterProductList.map((product, index) => {
              let getProductFromDato = products.find(datoProd => product.extraData?.centraId === datoProd.centraId.toString());
              if (getProductFromDato) {
                let prodFilter = productList.filter((prod) => {
                  return getProductFromDato.centraId.toString() === prod.centraProduct;
                })

                const addTrackingURL = product.url.split(getProductFromDato.slug)

                if (addTrackingURL.length > 1) {
                  getProductFromDato.slug = `${getProductFromDato.slug}${addTrackingURL[1]}`
                }

                let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                return (
                  <div key={index} className={shopStyles.cdShopProductCard}>
                    <ProductCard labelText={headerData.saleLabelText} labelBackground={headerData.saleLabelBackground.hex} color={headerData.saleLabelColor.hex} centraProd={centraProd} key={product.productNumber} data={getProductFromDato} position={index + 1} placement="Training List" />
                  </div>
                )
              } else {
                return null;
              }
              // return (
              //   <div key={index} className="cd-search-product">
              //     <SearchProductCard product={product} />
              //   </div>
              // )
            })
            }
          </div> :
            <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
              {
                products && products.map((element, index) => {
                  let prodFilter = productList.filter((prod) => {
                    return element.centraId.toString() === prod.centraProduct;
                  })

                  let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
                  return (
                    <div key={index} className={shopStyles.cdShopProductCard}>
                      <ProductCard labelText={headerData.saleLabelText} labelBackground={headerData.saleLabelBackground.hex} color={headerData.saleLabelColor.hex} centraProd={centraProd} key={element.id} data={element} position={index + 1} placement="Training List" />
                    </div>
                  )
                }
                )}
            </div>}
        </section>

      </div>
    </Layout>
  )
}